@tailwind base;
@tailwind components;
@tailwind utilities;

/*

body {
  font-size: 1rem;
  background-color: #f5f9fc;
}

h3 {
  font-family: "PT Serif";
  font-size: 14px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 15.4px;
}
p {
  font-family: "PT Serif";
  font-size: 14px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 20px;
}
blockquote {
  font-family: "PT Serif";
  font-size: 21px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 30px;
}
pre {
  font-family: "PT Serif";
  font-size: 13px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 18.5714px;
}

/*
 * Sidebar
 */

/*
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  z-index: 100; 
  padding: 12px 0 0; 
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; 

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
  font-size: 1.2rem;
  padding: 0.75rem 1rem;
}

.sidebar-heading {
  font-size: 0.75rem;
}

.sidebar .logo {
  width: 90%;
}

.nav-icon {
  min-width: 3rem;
  vertical-align: text-bottom;
}

.card {
  border-radius: 0.75rem;
  background-color: white;
  border: 0 solid #eceef3;
}

.card-body {
  padding: 0;
}

.table {
  margin-bottom: 0rem;
}

.table > :not(caption) > * > * {
  padding: 1rem;
}

.table thead th {
  font-size: 0.675rem;
  font-weight: 500;
  letter-spacing: 0.025em;
  text-transform: uppercase;
}

.table td {
  font-size: 0.8125rem;
}

.table > :not(:first-child) {
  border-top: 2px solid #e7eaf0;
}

tr:last-child {
  border-bottom: 0px solid transparent;
}

.update-balance-form table {
  background-color: white;
}
*/
